<template>
    <div>

        <!-- Otras intervenciones -->        
        <v-toolbar class="mt-3" short dense color="primary" dark flat>
            <v-toolbar-title>Intervenciones</v-toolbar-title>
        </v-toolbar>
        <v-row class="px-1">
            <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                <intervenciones-detalles
                    turno="mat"
                    :estaFecha="estaFecha"
                    :internamiento="internamiento"
                />
            </v-col>

            <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                <intervenciones-detalles
                    turno="ves"
                    :estaFecha="estaFecha"
                    :internamiento="internamiento"
                />
            </v-col>

            <v-col xs="12" sm="12" md="4" lg="4" xl="4" cols="12">
                <intervenciones-detalles
                    turno="noc"
                    :estaFecha="estaFecha"
                    :internamiento="internamiento"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import IntervencionesDetalles from '@/components/hospital/enfe/diagnosticos/IntervencionesDetalles'
export default {
    components:{
        'intervenciones-detalles': IntervencionesDetalles,
        
    },
    props:{
        estaFecha: String,
        internamiento: Number,
    },
}
</script>

<style>

</style>