<template>
    <v-card>
        <v-form
            v-model="intervencionForm"
            ref="interForm"
        >
            <v-card-title :class="turnoColor+' lighten-3'">
                <!--
                <div v-if="datosInter.id">
                    <v-icon
                        small
                        color="red "
                        v-if="tieneRol('ROLE_ENFERMERIA_ADMIN')||tieneRol('ROLE_ADMIN')"
                        @click="borraEntrada(datosInter.id)">
                            mdi-eraser
                    </v-icon>
                </div>
                -->
                {{turnoCompleto}}
                <v-spacer/>
                <!--
                <v-btn
                    v-if="!datosInter.id"
                    :disabled="disableGuarda"
                    :loading="loading"
                    
                    small
                    color="green"
                    dark
                    rounded
                    @click="guarda"
                >
                    <v-icon>mdi-content-save</v-icon>
                </v-btn>
                -->

                <v-btn
                        v-if="intervencionForm && !datosInter.cerrado"
                        :disabled="disableGuarda"
                        :loading="loading"
                        
                        small
                        color="green"
                        dark
                        rounded
                        @click="guarda(false)"
                    >
                        <v-icon>mdi-content-save</v-icon>
                    </v-btn>

                    <v-btn
                        v-if="datosInter.id && !datosInter.cerrado"
                        :disabled="!datosInter.id"
                        :loading="loading"
                        rounded
                        small
                        color="blue"
                        @click="cierra"
                    >
                        Cerrar
                    </v-btn>

                    <v-btn
                        v-if="datosInter.id && datosInter.cerrado && (this.tieneRol('ROLE_ENFERMERIA_ADMIN')||this.tieneRol('ROLE_ADMIN'))"
                        dark
                        x-small
                        color="red"
                        @click="borraEntrada(datosInter.id)"
                    >
                        <v-icon left>mdi-eraser</v-icon>
                        Borrar
                    </v-btn>


            </v-card-title>

            <v-card-text>
                    <div class="mt-3">
                        <v-select
                            v-model="datosInter.caidas"
                            :items="datosCaidas"
                            :rules="rules"
                            label="Prevención de CAIDAS"
                            multiple
                            chips
                            rounded
                            outlined
                            :readonly="Boolean(datosInter.cerrado)"
                        />

                        <v-select
                            v-model="datosInter.upp"
                            :items="datosUpp"
                            label="Prevención de UPP"
                            :rules="rules"
                            multiple
                            chips
                            rounded
                            outlined
                            :readonly="Boolean(datosInter.cerrado)"
                        
                        />

                        <v-select
                            v-model="datosInter.heridas"
                            :items="datosHeridas"
                            label="Cuidado de HERIDAS"
                            :rules="rules"
                            multiple
                            chips
                            rounded
                            outlined
                            :readonly="Boolean(datosInter.cerrado)"
                        />

                        <v-select
                            v-model="datosInter.oxigenoterapia"
                            :items="datosOxigenoterapia"
                            label="Oxigenoterapia"
                            :rules="rules"
                            multiple
                            chips
                            rounded
                            outlined
                            :readonly="Boolean(datosInter.cerrado)"
                        />

                        <v-textarea
                            v-if="datosInter.oxigenoterapia.includes('Ventilación mecánica')"
                            v-model="datosInter.descripcionVm"
                            label= 'Descripcion VENTILACIÓN MECÁNICA'
                            rounded
                            outlined
                            :readonly="Boolean(datosInter.cerrado)"

                        />

                        <v-select
                            v-model="datosInter.fototerapia"
                            :items="datosFototerapia"
                            label="Fototerapia"
                            :rules="rules"
                            rounded
                            outlined
                            :readonly="Boolean(datosInter.cerrado)"
                        />

                        <v-select
                            v-model="datosInter.accesosVasc"
                            :items="datosAccesosVasc"
                            label="Cuidado ACCESOS VASCULARES"
                            :rules="rules"
                            multiple
                            chips
                            rounded
                            outlined
                            :readonly="Boolean(datosInter.cerrado)"
                        />
                        
                        <!-- Para los accesos vaculres. Lo esconde si no es necesario -->
                        <v-sheet v-if="muestraAcceso" elevation="1" color="green lighten-5" class="pa-5 mb-3" >
                            <v-text-field
                                v-model="datosInter.vascuIntentos"
                                type="number"
                                rounded
                                outlined
                                dense
                                label="Número de intentos"
                                :readonly="Boolean(datosInter.cerrado)"
                            />

                            <v-text-field
                                v-model="datosInter.vascuSitio"
                                rounded
                                outlined
                                dense
                                label="Sitio de punción"
                                :readonly="Boolean(datosInter.cerrado)"
                            />

                            <v-text-field
                                v-model="datosInter.vascuTipoCalibre"
                                rounded
                                outlined
                                dense
                                label="Tipo y calibre de cateter"
                                :readonly="Boolean(datosInter.cerrado)"
                            />

                            <v-text-field
                                v-model="datosInter.vascuFechaInstala"
                                rounded
                                outlined
                                dense
                                :rules="ruleFechaHoraObligatoria"
                                label="Fecha y hora de instalación"
                                hint="Formato debe ser tipo 31/01/2021 15:45"
                                persistent-hint
                                :readonly="Boolean(datosInter.cerrado)"
                            />

                            <v-textarea
                                v-model="datosInter.otrosAccesosVasculares"
                                rounded
                                outlined
                                dense
                                label="Otros accesos vasculares"
                                :readonly="Boolean(datosInter.cerrado)"
                            />
                        </v-sheet>
                        <v-select
                            v-model="datosInter.sonda"
                            :items="tipoSonda"
                            label="Tipo de sonda instalada"
                            rounded
                            outlined
                            :readonly="Boolean(datosInter.cerrado)"
                            
                        />
                        <v-expand-transition>
                            <v-sheet v-if="datosInter.sonda > 0" elevation="1" color="orange lighten-5" class="pa-5 mb-1" >
                                <v-form ref="sonda">
                                    <v-text-field
                                        v-model="datosInter.sondaCalibre"
                                        rounded
                                        outlined
                                        dense
                                        label="Calibre"
                                        :rules="[value => !!value || 'Requerido.']"
                                        :readonly="Boolean(datosInter.cerrado)"
                                    />
                                    <v-text-field
                                        v-model="datosInter.sondaInstalacion"
                                        rounded
                                        outlined
                                        dense
                                        label="Fecha de instalación"
                                        :rules="ruleFechaObligatoria"
                                        hint="Formato debe ser tipo 31/01/2021"
                                        persistent-hint
                                        :readonly="Boolean(datosInter.cerrado)"
                                    />
                                    <v-text-field
                                        v-model="datosInter.sondaRetiro"
                                        rounded
                                        outlined
                                        dense
                                        label="Fecha de retiro"
                                        :rules="ruleFechaNoObligatoria"
                                        hint="Formato debe ser tipo 31/01/2021"
                                        persistent-hint
                                        :readonly="Boolean(datosInter.cerrado)"
                                    />
                                    <v-text-field
                                        v-model="datosInter.sondaObservaciones"
                                        rounded
                                        outlined
                                        dense
                                        label="Observaciones"
                                        :readonly="Boolean(datosInter.cerrado)"
                                    />
                                </v-form>
                            </v-sheet>
                        </v-expand-transition>


                        <v-checkbox
                            class="ml-10"
                            v-model="datosInter.vesical"
                            label="Sonda vesical instalada"
                            :true-value="true"
                            :false-value="false"
                            :readonly="Boolean(datosInter.cerrado)"
                        />

                        <v-expand-transition>
                            <v-sheet v-if="datosInter.vesical" elevation="2" color="blue lighten-5" class="pa-5 mb-1" >
                                <v-form ref="vesical">
                                    <v-text-field
                                        v-model="datosInter.vesicalCalibre"
                                        rounded
                                        outlined
                                        dense
                                        label="Calibre"
                                        :rules="[value => !!value || 'Requerido.']"
                                        :readonly="Boolean(datosInter.cerrado)"
                                    />
                                    <v-text-field
                                        v-model="datosInter.vesicalInstalacion"
                                        rounded
                                        outlined
                                        dense
                                        label="Fecha de instalación"
                                        :rules="ruleFechaObligatoria"
                                        hint="Formato debe ser tipo 31/01/2021"
                                        persistent-hint
                                        :readonly="Boolean(datosInter.cerrado)"
                                    />
                                    <v-text-field
                                        v-model="datosInter.vesicalRetiro"
                                        rounded
                                        outlined
                                        dense
                                        label="Fecha de retiro"
                                        :rules="ruleFechaNoObligatoria"
                                        hint="Formato debe ser tipo 31/01/2021"
                                        persistent-hint
                                        :readonly="Boolean(datosInter.cerrado)"
                                    />
                                    <v-text-field
                                        v-model="datosInter.vesicalObservaciones"
                                        rounded
                                        outlined
                                        dense
                                        label="Observaciones"
                                        :readonly="Boolean(datosInter.cerrado)"
                                    />
                                </v-form>
                            </v-sheet>
                        </v-expand-transition>


                        <span v-if="Boolean(datosInter.id)">Por: {{datosInter.creado}} {{datosInter.dateCreated}}</span> 

                    </div>
            </v-card-text>
        </v-form>
    </v-card>
</template>

<script>
export default {
    props:{
        turno: String,
        estaFecha: String,
        internamiento: Number,
    },
    mounted(){
        this.$refs.interForm.reset()
        
        this.cargaValoresTodos()
    },
    data:()=>({
        loading:false,
        intervencionForm:false,
        rules:[
            value => !!value || 'Requerido.',
            value => value?value.length > 0:null || 'Requerido.'
        ],
        rules2:[
            value => !!value || 'Requerido.',
            value => parseFloat(value) >= 0|| 'Requerido.'
        ],

        ruleFechaObligatoria:[
            value => !!value || "Requiere fecha de instalación",
            value => /^([0-2][0-9]|3[0-1])(\/)(0[1-9]|1[0-2])\2(\d{4})$/.test(value) || "Formato debe ser tipo 31/01/2021",
        ],
        ruleFechaHoraObligatoria:[
            value => !!value || "Requiere fecha de instalación",
            value => /^([0-2][0-9]|3[0-1])(\/)(0[1-9]|1[0-2])\2(\d{4})(\s)([0-1][0-9]|2[0-3])(:)([0-5][0-9])$/.test(value) || "Formato debe ser tipo 31/01/2021 15:45",
        ],
        ruleFechaNoObligatoria:[
            value => value?/^([0-2][0-9]|3[0-1])(\/)(0[1-9]|1[0-2])\2(\d{4})$/.test(value)||"Formato debe ser tipo 31/01/2021":true
        ],


        datosCaidas:[
            'Ayuda a la deambulación',
            'Colocación de barandillas',
            'Sujeción gentil',
            'Orientación sobre riesgo',
            'Otros',
            'No se requiere',
        ],
        
        datosUpp:[
            'Limpieza de piel',
            'Movilización pasiva',
            'Orientación para prevención',
            'Cambio de posturas',
            'Uso de elementos disponibles',
            'No se requiere'
        ],
        
        datosHeridas:[
            'Valoración de la lesion',
            'Valoración de estomas',
            'Curación de la herida',
            'Vigilar signos de infeccion',
            'Vigilar sangrado',
            'Vigilar exudado',
            'No se requiere',
        ],

        datosAccesosVasc:[
            'Curación',
            'Evaluacion de sitio',
            'No se requiere',
        ],

        datosOxigenoterapia:[
            'Flujo libre',
            'Casco cefálico',
            'Puntas nasales',
            'CPAP',
            'Ventilación mecánica',
            'Otro',
            'No se requiere',
        ],

        datosFototerapia:[
            'Simple',
            'Doble',
            'Fibra óptica',
            'No se requiere',
        ],
        tipoSonda:[
            {value:0, text: 'No requiere'},
            {value:1, text: 'Nasogasrtica'},
            {value:2, text: 'Orogástrica'},
            {value:3, text: 'Gastrostomia'},
        ],

        datosInter:{
            id:null,
            caidas:[],
            upp:[],
            heridas:[],
            accesosVasc:[],
            oxigenoterapia:[],
            fototerapia:'', //<--------
            descripcionVm:'', //<--------
            vascuIntentos:0,
            vascuSitio:'',
            vascuTipoCalibre:'',
            vascuFechaInstala:'',
            creado:'',
            dateCreated:'',
            otrosAccesosVasculares:'', //<--------
            
            vesical:false,

            sondaCalibre:'',
            sondaInstalacion:'',
            sondaRetiro:'',
            sondaObservaciones:'',

            vesicalCalibre:'',
            vesicalInstalacion:'',
            vesicalRetiro:'',
            vesicalObservaciones:'',
        }
        
    }),
    methods:{

        cierra(){
            this.loading = true
            this.$swal.fire({
                title: '¿Desea cerrar?',
                text: "Una vez cerrado no se podrá modificar",
                icon: 'question',
                showCancelButton: true,

                confirmButtonColor: 'green',
                cancelButtonColor: 'red',
                
                cancelButtonText: 'No',
                confirmButtonText: 'Si, Guarda'
            }).then(async (result) => {
                this.loading = false
                if (result.value) {
                    this.guarda(true)
                }
            })
        },
                    
        tieneRol(rol){
            let contiene = this.$store.state.security.roles.includes(rol)
            return (contiene)
        },

        async borra2(id,motivo){
            try{
                let borra = await this.$http({
                    method:'DELETE',
                    url:'/enfermeria/borraIntervencion',
                    data:{id:id, motivo:motivo}
                })
                if (borra.data.estado === true){
                    this.datosInter.cerrado=false
                    this.datosInter.fechaCierra=null
                    this.datosInter.id=null
                    this.cargaValoresTodos()
                } else {
                    console.log(borra.data)
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede eliminar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
                
            } catch(err){
                this.$store.dispatch('security/revisaError',err)
            }
        },

        borraEntrada(id){
            //let motivo 
            this.$swal.fire({
                title: 'Borrar esta entrada',
                text: 'Motivo para borrar esta entrada',
                input: 'text',
                //inputValue:motivo,
                confirmButtonText: 'Borrar registro',
                showLoaderOnConformation: true,
                inputValidator:  (value) => {
                    if (value) {

                        this.$swal.fire({
                            title:'¿Desea borrar?',
                            text:'Esta acclón no se puede deshacer',
                            icon: 'warning',
                            showCancelButton: true
                        }).then((result)=>{
                            if (result.value){
                                this.borra2(id,value)
                            }
                        })
                    } else {
                        return 'Indique el motivo para esta eliminación'
                    }
                }
            })
        },


        async guarda(cerrado){
            this.loading = true
            
            let params = this.datosInter
            params.internamiento= this.internamiento
            params.fecha= this.estaFecha
            params.turno=this.turno
            params.cerrado=cerrado

            try{
                let int = await this.$http({
                    method:'POST',
                    url:'/enfermeria/guardaIntervenciones',
                    data:params
                })

                this.loading=false

                if (int.data.estado == true){
                    this.cargaValoresTodos()
                } else {
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
            } catch(err){
                this.loading = false
                this.$store.dispatch('security/revisaError',err)
            }
        },
        async cargaValoresTodos(){
            this.datosInter = {}
            this.$refs.interForm.reset()
            this.datosInter.vesical=false
            this.loading=true

            let params = {
                internamiento:this.internamiento,
                fecha: this.estaFecha,
                turno: this.turno,
            }

            try{
                let li = await this.$http({
                    method:'GET',
                    url: '/enfermeria/listIntervenciones',
                    params:params
                })
                //console.log(li.data)
                if (li.data.estado == true){
                    this.loading=false
                    
                    li.data.root.caidas=li.data.root.caidas.split(',')
                    li.data.root.upp=li.data.root.upp.split(',')
                    li.data.root.heridas=li.data.root.heridas.split(',')
                    li.data.root.accesosVasc=li.data.root.accesosVasc.split(',')
                    li.data.root.oxigenoterapia=li.data.root.oxigenoterapia.split(',')
                    this.datosInter = li.data.root
                } else {
                    this.loading=false
                }
                
            }catch(err){
                console.log(err)
                this.$store.dispatch('security/revisaError',err)
                this.loading = false
            }

            
        },

        calculaFecha(v){
            return /^([0-2][0-9]|3[0-1])(\/)(0[1-9]|1[0-2])\2(\d{4})$/.test(v)
        },
        calculaFechaHora(v){
            return /^([0-2][0-9]|3[0-1])(\/)(0[1-9]|1[0-2])\2(\d{4})(\s)([0-1][0-9]|2[0-3])(:)([0-5][0-9])$/.test(v)
        },
    },
    computed:{
        turnoCompleto(){
            let text = ''

            switch (this.turno){
                case 'mat':
                    text = 'Matutino'
                    break
                case 'ves':
                    text = "Vespertino"
                    break
                case 'noc':
                    text = 'Nocturno'
                    break
            }
            return text
        },
        turnoColor(){
            let text = ''

            switch (this.turno){
                case 'mat':
                    text = 'blue'
                    break
                case 'ves':
                    text = "green"
                    break
                case 'noc':
                    text = 'red'
                    break
            }
            return text
        },
        
        muestraAcceso(){
            let valor = false

            if (this.datosInter.accesosVasc && this.datosInter.accesosVasc.includes('No se requiere')){
                valor=false
            }

            if (
                this.datosInter.accesosVasc && (
                    this.datosInter.accesosVasc.includes('Evaluacion de sitio') || 
                    this.datosInter.accesosVasc.includes('Curación'))
                ){
                valor=true
            }

            return valor

            
        },

        muestraBorrar(){
            return (this.datosInter.cerrado && (this.tieneRol('ROLE_ENFERMERIA_ADMIN')||this.tieneRol('ROLE_ADMIN')))
        },
        
        interForm(){
            return ('intEval'+this.turno)
        },
        disableGuarda(){
            //console.log(this.intervencionForm)
            if (this.intervencionForm){
                return false
            } else {
                return true
            }
        },
    },
    watch:{
        estaFecha(){
            this.cargaValoresTodos()
        },
        'datosInter.sonda'(v){
            if (v==0){
                if (this.$refs.sonda){
                    this.$refs.sonda.reset()
                }
            }
        },
        'datosInter.vesical'(v){
                if (!v){
                    this.$refs.vesical.reset()
                }
        }
    },
}
</script>

<style>

</style>